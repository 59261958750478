// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require('jquery')

import AOS from 'aos/dist/aos.js';
import 'aos/dist/aos.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import 'slick-carousel/slick/slick.min.js';
import flexImages from "javascript-flex-images/flex-images.min.js";
import '../src/site.scss';
import animateScrollTo from 'animated-scroll-to';

const jQuery = require( 'jquery' );
const loadGoogleMapsApi = require('load-google-maps-api');

window.jQuery = $;
window.$ = $;

window.addEventListener('load', () => {
  AOS.init();
  startFlexImages();
  setAffixMinHeight();
});


document.addEventListener("DOMContentLoaded", function() {
  // setAffixMinHeight();
  startFlexImages();
  HideCookiesNotice();
});


// $(document).on('DOMContentLoaded', function() {
document.addEventListener("DOMContentLoaded", function() {

  affix();
  drawGoogleMap();
  openTabFromUrl();
  AOS.init();
  startFlexImages();
  scrollToAnchor();
  loadCountDown();


  $('.dropdown-toggle').dropdown();

  // Slider
  $('.slick').slick({
     prevArrow: $('.slick-prev-button'),
     nextArrow: $('.slick-next-button'),
  });

  $(".slick-next-step-btn").click(function() {
    $('.slick').slick('slickNext');
  });

  $(".slick-prev-step-btn").click(function() {
    $('.slick').slick('slickPrev');
  });

  $('.homepage-slider').slick({
    autoplay: true,
    autoplaySpeed: 4500
  });

  $('.webmix-slider').slick({
    autoplay: true,
    autoplaySpeed: 4500,
    fade: true
  });

  // Slider
  $('.homepage-slider-fade').slick({
    autoplay: true,
    autoplaySpeed: 4500,
    fade: true
  });

  $('.uniq-carousel').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    fade: true
  })

  $('.carousel').slick();

  $('.logo-carousel').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });



  $('.logo-carousel-6').slick({
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

  $('.testi-carousel').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

  $('.logistic-carousel').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        arrows: false,
        dots: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

  $('.osmont-carousel').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        arrows: false,
        dots: true,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

    $('.testi-carousel-4').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        dots: true
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    ]
  });

  $('.d2s-carousel').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
    {
      breakpoint: 768,
      settings: "unslick"
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });


  $('.text-carousel').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  });



  $('.items-carousel').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

    $('.single-carousel').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });


});


$(document).ready(function() {
  // startFlexImages();
  openTabFromUrl();
});


function startFlexImages(){
  new flexImages({ selector: '.flex-images', rowHeight: 360 });
  new flexImages({ selector: '.flex-images-small', rowHeight: 180 });
}


function setAffixMinHeight() {
    var affix_min_height = $('.affix-min-height').height();
    $('.affix-min-height').css('min-height', affix_min_height);
}

function affix() {
  window.onscroll = scroll;
  function scroll () {
    var scrollTop = window.pageYOffset;
    var affix_height = $('.affix-height').height();
    if( scrollTop > affix_height ){
      $('#main-navbar').addClass('fixed-navbar');
      $('#main-navbar').addClass('fadeIn');
    }
    else
    {
      $('#main-navbar').removeClass('fixed-navbar');
      $('#main-navbar').removeClass('fadeIn');
    }
  }

}

function drawGoogleMap() {

  if( $('#gmap').length ) {

    var site_lat = parseFloat(document.getElementById('lat').value);
    var site_lng = parseFloat(document.getElementById('lng').value);
    var site_address = document.getElementById('address').value;


    loadGoogleMapsApi({ key: 'AIzaSyB5XTXHqXSA67eM8sD0zCFXul8d-3-HZos' }).then(function (googleMaps) {

      var location = {lat: site_lat, lng: site_lng};

      var map = new googleMaps.Map(document.querySelector('#gmap'), {
        center: {
          lat: site_lat,
          lng: site_lng
        },
        zoom: 14
      })

      var marker = new googleMaps.Marker({position: location, map: map});

      var infowindow = new googleMaps.InfoWindow({
        content: site_address
      });

      marker.addListener('click', function() {
        infowindow.open(map, marker);
      }, { passive: true });



    }).catch(function (error) {
      console.error(error)
    })
  }

}

function loadCountDown(){

    const updateCountDown = (element, targetDate) => {

      const remainingTime = getRemainingTime(targetDate);

      if(remainingTime.total <= 0) return true

      $(element).find('.days .value').text(remainingTime.days.toString());
      $(element).find('.hours .value').text(remainingTime.hours.toString());
      $(element).find('.minutes .value').text(remainingTime.minutes.toString());
      $(element).find('.seconds .value').text(remainingTime.seconds.toString());


      return false

    }

    $('.countdown').each((index, element) => {

      const fetchedTarget = new Date($(element).data('countdown'));
      fetchedTarget.setHours(fetchedTarget.getHours() - 1);
      const targetDate = fetchedTarget.getTime();

      $(element).addClass('d-flex aic');

      $(element).html(`
          <div class="days d-flex fc aic jcc"><span class="value">00</span><span class="label">Jours</span></div> :
          <div class="hours d-flex fc aic jcc"><span class="value">00</span><span class="label">Heures</span></div> :
          <div class="minutes d-flex fc aic jcc"><span class="value">00</span><span class="label">Minutes</span></div> :
          <div class="seconds d-flex fc aic jcc"><span class="value">00</span><span class="label">Secondes</span></div>
      `);

      const finished = updateCountDown(element, targetDate);

      if(!finished) {
        let interval = setInterval(function() {
          const finish = updateCountDown(element, targetDate);
          if(finish) clearInterval(interval);
        }, 1000)
      }
    })

}

function getRemainingTime(targetDate) {

  function padZero(value) {
    return value < 10 ? `0${value}` : value;
  }


  const now = new Date().getTime();
  const distance = targetDate - now;

  const remainingTime = {
    days: padZero(Math.floor(distance / (1000 * 60 * 60 * 24))),
    hours: padZero(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))),
    minutes: padZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))),
    seconds: padZero(Math.floor((distance % (1000 * 60)) / 1000)),
    total: distance
  };

  return remainingTime;
}

function openTabFromUrl() {
  if ($(window).width() > 767) {
    let url = location.href.replace(/\/$/, "");

    if (location.hash) {
      const hash = url.split("#");
      $('#dynTab a[href="#'+hash[1]+'"]').tab("show");
      url = location.href.replace(/\/#/, "#");
      history.replaceState(null, null, url);
      setTimeout(() => {
        scrollTo( $("#myTabContent") );
      }, 400);
    }

    $('a[data-toggle="tab"]').on("click", function() {
      let newUrl;
      const hash = $(this).attr("href");
      if(hash == "#home") {
        newUrl = url.split("#")[0];
      } else {
        newUrl = url.split("#")[0] + hash;
      }
      newUrl += "/";
      history.replaceState(null, null, newUrl);
    });
  } else {
    // let url = location.href.replace(/\/$/, "");
    // const hash = url.split("#");
    // setTimeout(() => {
    //   scrollTo( $("#"+hash[1]) );
    // }, 400);
  }
}


function scrollTo( target ) {
    if( target.length ) {
        $("html, body").stop().animate( { scrollTop: target.offset().top - $("#main-navbar").height() }, 500);
    }
}


function HideCookiesNotice() {

  var cookie = getCookie("HideCookiesNotice");
  if (cookie) {
    $('.cookies-notice').hide();
  }

  $('.cookies-notice').click(function(){
    console.log(document.cookie)
    $(this).fadeOut();


    document.cookie = "HideCookiesNotice=true";
    var date = new Date();
    date.setMonth(date.getMonth()+6);
    document.cookie += ("; expires=" + date.toUTCString());
  })
}


function getCookie(c_name) {
    var c_value = document.cookie,
        c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start == -1) c_start = c_value.indexOf(c_name + "=");
    if (c_start == -1) {
        c_value = null;
    } else {
        c_start = c_value.indexOf("=", c_start) + 1;
        var c_end = c_value.indexOf(";", c_start);
        if (c_end == -1) {
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
}


// function animateScrollTo(scrollToElement, options);



function scrollToAnchor(selector) {

  $('.scrollTo').on("click", function() {
    $('.navbar-collapse').collapse('hide');
    var target = $(this).data('target');
    animateScrollTo(document.querySelector(target), {verticalOffset: -100 })
  });

}



